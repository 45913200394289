body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html.full-screen, .full-screen body, .full-screen #root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #fff;
}
.gradient {
  background-image: linear-gradient(to right,#f27b37 0,#ed495e 100%),linear-gradient(to right,#f27b37 0,#ed495e 100%);
}
.flex-grow-2 {
  flex-grow: 2;
}
.btn-circle {
  width: 36px;
  height: 36px;
  padding: 0;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 18px;
  border-width: 2px;
}
.avatar {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.progress-white {
  background-color: rgba(255,255,255,.2);
}
.bg-coin {
  background-color: #f18c84;
}
.bg-dark {
  background-color: #262525!important;
}
.overflow-hidden-x {
  overflow-x: hidden;
}
.right-0 {
  right: 0;
}
.font-sm {
  font-size: 0.62em;
}
.fill-available {
  min-height: 100vh
}
.thumbNailAvatarView img {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-image: linear-gradient(to right,#f27b37 0,#ed495e 100%),linear-gradient(to right,#f27b37 0,#ed495e 100%);
  padding: 3px;
  object-fit: cover;
}
.thumbNailView img {
  height: 40px!important;
  width: unset!important;
}
.chiproot {
  color: rgba(0, 0, 0, 0.87);
  height: 32px;
  cursor: default;
  border: none;
  display: inline-flex;
  outline: none;
  padding: 0;
  font-size: 0.8125rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  margin: 4px;
}
.chiplabel {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
@media all and (min-width: 1200px) {
  .text-right-xl {
      text-align: right;
  }
}

@media all and (min-width: 768px) {
  .h-content {
    height: calc(100vh - 66px);
  }
  .h-100-md {
    height: 100%;
  }
  .scroll-y-md {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;     
  }
}

.skeleton {
  animation: skeleton 1.5s ease-in-out infinite;
}

@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.full-size {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.maphashes {
  position: absolute;
  background-color: #212121;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
}

.stat-display {
  position: absolute;
  top: 0;
  left: 0;
}

.stat-display-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 30px;
}

.bg-opaque {
  background-color: rgba(100, 100, 100, 0.8);
}

.mapboxgl-popup {
  z-index: 1;
}
.hash-href {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.lh-1 {
  line-height: 1.2em;
}

.nav-search {
  width: 142px;
}

@media (min-width: 480px) {
  .nav-search {
    width: 232px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
      margin: 6.75rem auto;
  }

  .nav-search {
    width: 320px;
  }
  
}

.blue-background-class {
  background-color: rgba(9, 186, 166, 30%)!important;
}
.no-drag {
  background-color: rgba(0, 0, 0, 10%)!important;
}

.mapboxgl-popup-content {
  width: 400px;
}

.theme-dark .mapboxgl-popup-content {
  background-color: #333333;
}

.theme-dark .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #333333;
}

.theme-dark .card {
  /* TODO: REFACTOR COMPONENTS THAT USE card CLASS to material-ui */
  background-color: #333333;
}

.theme-dark .list-group-item {
  /* TODO: REFACTOR COMPONENTS THAT USE list-group-item CLASS to material-ui */
  background-color: #333333;
}

.theme-dark .form-control {
  /* TODO: REFACTOR COMPONENTS THAT USE form-control CLASS to material-ui */
  background-color: #333333;
  color: #fff;
}

.theme-dark .close {
  color: #fff;
}

.theme-dark .modal-content {
  /* TODO: REFACTOR COMPONENTS THAT USE form-control CLASS to material-ui */
  background-color: #333333;
}

.theme-dark .highcharts-title tspan {
  color: #fff;
  fill: #fff;
}

.theme-dark text.highcharts-title, .theme-dark .highcharts-axis-labels text {
  color: #fff!important;
  fill: #fff!important;
}

a {
  color: #09BAA6;
}
a:hover {
  color: #0ad6be;
}

button:focus {
  outline: none;
}

*::-webkit-scrollbar {
  width: 0.5rem;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.swal2-container {
  z-index: 1301;
}

@media screen and (max-width: 500px) {
  .notistack-SnackbarContainer {
    bottom: 64px!important;
  }
}

.disabled-w-0 {
  transition: width 0.3s ease-in;
}
.MuiButtonBase-root.Mui-disabled.disabled-w-0 {
  width: 0;
}
.h-18px,.MuiAvatar-root.h-18px { height: 18px; }
.w-18px,.MuiAvatar-root.w-18px { width: 18px; }

.no-children-mt-2 {
  margin-top: 8px;
}
.no-children-mt-2:empty {
  margin-top: 0;
}

.limited-release-item div[data-lastpass-icon-root] {
  display: none;
} 